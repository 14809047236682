<template>
    <el-popover trigger="hover" placement="top" title="路单异常" width="180" :disabled="!statusListCom.length" v-if="isShow">
        <div class="popover_content" v-for="item in statusListCom" :key="item.name" v-show="item.errorId != 9">
            <div :style="{background:item.color}" class="popover_color"></div>
            <div v-text="item.name"></div>
        </div>
        <div slot="reference" class="name-wrapper">{{ rowValue }}</div>
    </el-popover>
    <div v-else>{{ rowValue }}</div>
</template>
<script>
export default {
    name: '',
    props: {
        statusList: {
            type: Array,
            default: function() {
                return []
            }
        },
        rowValue: {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {}
    },

    watch: {},

    components: {},

    computed: {
        statusListCom: function() {
            // console.log(this.statusList)
            if (this.statusList) {
                return this.statusList;
            } else {
                return []
            }
        },
        isShow(){
            // console.log(this.statusList)
            let isShow = true
            let arr = this.statusList&&this.statusList.length&&this.statusList.filter(item=>{
                return item.errorId != 9
            })
            if(arr&&arr.length==0){
                isShow = false
            }
            return isShow
        },
    },

    beforeMount() {},

    mounted() {},

    activated() {},

    created() {},

    methods: {}
}

</script>
<style lang='scss' scoped>
.popover_content {
    display: flex;
    justify-content: flex-start;

    .popover_color {
        height: 15px;
        width: 15px;
        margin-right: 10px;
    }
}

</style>
