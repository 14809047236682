<template>
    <div class="dirctionShow">
        <jy-dialog
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_form"
            width="420px"
        >
            <div class="dirctionShow_content" v-for="item in dataList" :key="item.name">
                <div :style="{background:item.color}" class="dirctionShow_color"></div>
                <div v-text="item.name" class="dirctionShow_text"></div>
            </div>
            <div class="dirctionShow_explain">说明:出现多种异常时,按从上到下优先显示,鼠标移入可以查看该条班次所有异常信息</div>
            <div class="dialog-footer" slot="footer">
                <el-button @click="cancel">取 消</el-button>
                <!-- <el-button type="primary" @click="save">保 存</el-button> -->
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
  data() {
    return {
      isCheck: false,
      title: '异常信息',
      batchdeleteList: [],
      dataList: [],
      dialogVisible: false,
      formLabelWidth: '100px'
    }
  },
  components: {},
  activated() {
    this.getDirtion()
  },
  created() {},
  watch: {
    dialogVisible(newview) {
      if (!newview) {
        // this.resetForm('dialogForm')
      }
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
    },
    getDirtion() {
      let option = {
        type: 0
      }
      let url = '/dictError/selectList'
      this.$http.post(url, option).then(({ detail }) => {
        this.dataList = detail
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    }
  }
}
</script>
<style lang='scss' scoped>
.dirctionShow_content {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px 10px 40px;

  .dirctionShow_color {
    height: 25px;
    width: 25px;
    margin-right: 20px;
  }
  .dirctionShow_text {
    font-size: 14px;
  }
}
.dirctionShow_explain {
  margin-left: 40px;
  margin-top: 20px;
}
</style>