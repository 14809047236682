<template>
    <div class="trackReplay">
        <jy-dialog :title="title" :visible.sync="dialogVisible" custom-class="dialog_track" type="table" :width="dialogTrackWidth">
            <div class="trackReplay_box">
                <div class="trackReplay_top">
                    <el-form :model="diaform" ref="dialogForm" :disabled="isCheck" :rules="rules" :label-width="formLabelWidth">
                        <el-row class="search" type="flex">
                            <el-col :span="14">
                                <el-row>
                                    <el-col :span="8">
                                        <el-form-item  label="选择车辆:">
                                            <el-input v-model="diaform.vehicleNo" disabled></el-input>
                                            <!-- <el-input v-model="diaform.vehicleNo" placeholder="请输入" clearable @focus="carSelect"></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10" :pull="3">
                                        <el-form-item prop="time">
                                            <el-date-picker v-model="diaform.time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">
                                        <el-form-item>
                                            <el-button type="primary" @click="oncheck">查询</el-button>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">
                                        <el-form-item>
                                            <el-button type="primary" @click="resetForm('dialogForm')">重置</el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="10">
                                <el-col :span="10" :push="3">
                                    <el-form-item prop="multiple" label="回放速度:">
                                        <el-select v-model="diaform.multiple" placeholder="请选择">
                                            <el-option v-for="item in speedList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3">
                                    <el-form-item>
                                        <el-button type="primary" @click="toStart" :disabled="isToStart">回放</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3">
                                    <el-form-item>
                                        <el-button type="primary" @click="toPause" :disabled="isToPauseStop">暂停</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="3">
                                    <el-form-item>
                                        <el-button type="primary" @click="toStop" :disabled="isToPauseStop">停止</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="trackReplay_map">
                    <track-replay-map ref="trackReplayMap" @haslist="isTackPlay"></track-replay-map>
                </div>
            </div>
        </jy-dialog>
        <depotcar-select ref="carSelectDialog" @updateSite="onCarSite"></depotcar-select>
    </div>
</template>
<script>
import trackReplayMap from '@/components/pages/admin/waybillManagement/trackReplay/trackReplayMap'
import depotcarSelect from '@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelect'

export default {
    data() {
        return {
            isCheck: false,
            title: '轨迹回放',
            canHide: true,
            isHide: false,
            isToStart: true,
            isToPauseStop: true,
            speedList: [{
                    value: 1,
                    label: '1X'
                },
                {
                    value: 10,
                    label: '10X'
                },
                {
                    value: 30,
                    label: '30X'
                },
                {
                    value: 50,
                    label: '50X'
                },
                {
                    value: 100,
                    label: '100X'
                },
                {
                    value: 300,
                    label: '300X'
                }
            ],
            batchdeleteList: [],
            diaform: {
                vehicleNo: '',
                vehicleId: '',
                clientId: '',
                multiple: 1,
                time: [
                    new Date().Format('yyyy-MM-dd ') + '09:37:00',
                    new Date().Format('yyyy-MM-dd ') + '17:37:00'
                ],
                endTime: '',
                beginTime: ''
            },
            rules: {
                vehicleNo: [{
                    required: true,
                    message: '请选择车辆',
                    trigger: ['blur', 'change']
                }]
            },
            mapStyle: {},
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dialogVisible: false,
            formLabelWidth: '100px',
            dialogTrackWidth: '80%'
        }
    },
    components: {
        trackReplayMap,
        depotcarSelect
    },
    activated() {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.$refs.trackReplayMap.pageIndex = 1
            }
        },
        'diaform.vehicleNo': function(val, oldVal) {
            if (val != oldVal) {
                if (this.diaform.vehicleNo == '') {
                    this.diaform.vehicleId = ''
                }
            }
        }
    },
    methods: {
        init(option, type) {
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    let selectOption = JSON.parse(JSON.stringify(option))
                    // if (type == 'fixedLine') {
                    //     //定点专线补录
                    //     if (selectOption.vehicleId) {
                    //         this.diaform.vehicleId = selectOption.vehicleId
                    //         this.diaform.vehicleNo = selectOption.vehicleNo
                    //         this.diaform.clientId = selectOption.vin
                    //     } else if (selectOption.planVehicleId) {
                    //         this.diaform.vehicleId = selectOption.planVehicleId
                    //         this.diaform.vehicleNo = selectOption.planVehicleNo
                    //         this.diaform.clientId = selectOption.planVin
                    //     }
                    // } else {
                    //     if (selectOption.actualVehicleId) {
                    //         this.diaform.vehicleId = selectOption.actualVehicleId
                    //         this.diaform.vehicleNo = selectOption.actualVehicleNo
                    //         this.diaform.clientId = selectOption.actualVin
                    //     } else if (selectOption.planVehicleId) {
                    //         this.diaform.vehicleId = selectOption.planVehicleId
                    //         this.diaform.vehicleNo = selectOption.planVehicleNo
                    //         this.diaform.clientId = selectOption.planVin
                    //     }
                    // }
                    if (selectOption.vehicleId) {
                        this.diaform.vehicleId = selectOption.vehicleId
                        this.diaform.vehicleNo = selectOption.vehicleNo
                        this.diaform.clientId = selectOption.vin
                    } else if (selectOption.actualVehicleId) {
                        this.diaform.vehicleId = selectOption.actualVehicleId
                        this.diaform.vehicleNo = selectOption.actualVehicleNo
                        this.diaform.clientId = selectOption.actualVin
                    } else if (selectOption.planVehicleId) {
                        this.diaform.vehicleId = selectOption.planVehicleId
                        this.diaform.vehicleNo = selectOption.planVehicleNo
                        this.diaform.clientId = selectOption.planVin
                    }
                    //   时间
                    if (type == 'waybill' || type == 'workTime') {
                        if (selectOption.beginTime) {
                            let beginTime =
                                new Date().Format('yyyy-MM-dd ') + selectOption.beginTime
                            this.$set(this.diaform.time, 0, beginTime)
                        }
                        if (selectOption.endTime) {
                            let endTime =
                                new Date().Format('yyyy-MM-dd ') + selectOption.endTime
                            this.$set(this.diaform.time, 1, endTime)
                        }
                    }

                    this.oncheck()
                })
            }
        },
        // 查询
        oncheck() {
            if (this.diaform.time.length) {
                this.diaform.beginTime = this.diaform.time[0]
                this.diaform.endTime = this.diaform.time[1]
            }
            this.$refs.trackReplayMap.getGpsData(this.diaform)
        },
        resetForm(formName) {
            console.log(formName)
            // this.$refs[formName].resetFields()
            this.diaform.time = [
                new Date().Format('yyyy-MM-dd ') + '09:37:00',
                new Date().Format('yyyy-MM-dd ') + '17:37:00'
            ]
            this.diaform.multiple = 1
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        // 是否可回放
        isTackPlay(option) {
            if (option) {
                this.isToStart = false
                this.isToPauseStop = true
            } else {
                this.isToStart = true
                this.isToPauseStop = true
            }
        },
        // 车辆选择
        carSelect() {
            this.$refs.carSelectDialog.init()
        },
        onCarSite(siteArr) {
            // 选择的车辆及对应的VIN显示
            console.log(siteArr)
            siteArr = JSON.parse(JSON.stringify(siteArr))
            this.diaform.vehicleNo = siteArr.vehicleNo
            this.diaform.vehicleId = siteArr.vehicleId
        },
        // 开始
        toStart() {
            this.isToStart = true
            this.isToPauseStop = false
            this.$refs.trackReplayMap.initStart(this.diaform)
        },
        // 停止
        toStop() {
            this.isToStart = false
            this.isToPauseStop = true
            this.$refs.trackReplayMap.initStop()
        },
        // 暂停
        toPause() {
            this.isToStart = false
            this.isToPauseStop = true
            this.$refs.trackReplayMap.initPause()
        }
    }
}

</script>
<style lang='scss' scoped>
</style>
