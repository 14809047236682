<template>
	<div class="track_map">
		<div id="track_map_box"></div>
		<div class="trackReplay_table" v-bind:class="{'hidden':isHide}">
			<div class="arrow_box" v-if="canHide" v-on:click="setIsHide()">
				<div class="i_box" :class="{'to_left':isHide}">
					<i class="el-icon-arrow-down"></i>
				</div>
			</div>
			<div class="jy_table">
				<jy-table tooltip-effect="dark" :data="dataList" :height="tableHeigth" v-loading="loading">
					<!-- <jy-table-column type="index" label="序号" width="60"></jy-table-column> -->
					<jy-table-column prop="vin" label="VIN号" min-width="120"></jy-table-column>
					<jy-table-column prop="lng" label="经度" min-width="120"></jy-table-column>
					<jy-table-column prop="lat" label="纬度" min-width="120"></jy-table-column>
					<jy-table-column prop="spd" label="车速(km/h)" min-width="120"></jy-table-column>
					<!-- <jy-table-column prop="mileage" label="里程km" min-width="120"></jy-table-column> -->
					<jy-table-column prop="address" label="位置" min-width="120"></jy-table-column>
					<jy-table-column prop="time" label="上报时间" min-width="120"></jy-table-column>
				</jy-table>
				<div v-if="!isHide">
					<jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import startIcon from "@/assets/startIcon.png";
	import endIcon from "@/assets/endIcon.png";

	export default {
		data() {
			return {
				map: null, //地图
				speed: 200, //速度
				lushu: {}, //路书
				gpsData: [], //gps数据
				//条件数据
				option: {
					multiple: 1,
				},
				canHide: true,
				isHide: false,
				dataList: [],
				pageSize: 10,
				total: 100,
				pageIndex: 1,
				loading: true,
				tableHeigth: 200,
				startIcon,
				endIcon,
			};
		},
		props: {},

		watch: {
			isHide: function (newVal) {
				if (newVal) {
					this.tableHeigth = 0;
				} else {
					this.tableHeigth = 200;
				}
			},
		},

		components: {},

		computed: {},

		beforeMount() {},

		mounted() {
			this.getMap();
		},

		activated() {
			this.$nextTick(() => {
				this.getMap();
			});
		},

		created() {},

		methods: {
			//   获取地图
			getMap() {
				this.map = new BMap.Map("track_map_box");
				this.map.enableScrollWheelZoom();
				this.map.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
			},
			//   获取GPS数据
			getGpsData(params) {
				console.log(params);
				this.loading = true;
				this.map.clearOverlays();
				this.isHide = false;

				let url = "/stat/tellData/getGpsDataByDevice";
				let option = {
					err: () => {
						this.loading = false;
					},
				};
				this.$http.post(url, params, option).then(
					(res) => {
						res.detail.forEach((item, k) => {
							let time = "";
							time =
								"20" +
								item.time.slice(0, 2) +
								"-" +
								item.time.slice(2, 4) +
								"-" +
								item.time.slice(4, 6) +
								" " +
								item.time.slice(6, 8) +
								":" +
								item.time.slice(8, 10) +
								":" +
								item.time.slice(10, 12);
							res.detail[k].time = time;
							item.vin = res.retain;
						});
						this.gpsData = res.detail;

						var arrPois = [];
						for (let i = 0; i < this.gpsData.length; i++) {
							var p0 = this.gpsData[i].lng;
							var p1 = this.gpsData[i].lat;
							var point = new BMap.Point(p0, p1);
							arrPois.push(point);
						}
						//   console.log('经纬度：', arrPois)
						// 画轨迹
						// this.map.addOverlay(
						// 	new BMap.Polyline(arrPois, { strokeColor: "blue" })
						// );
						this.getPolyline(arrPois);
						this.map.setViewport(arrPois);

						//   添加标注
						if (arrPois.length) {
							let startPoint = new window.BMap.Point(
								arrPois[0].lng,
								arrPois[0].lat
							);
							let endPoint = new window.BMap.Point(
								arrPois[arrPois.length - 1].lng,
								arrPois[arrPois.length - 1].lat
							);
							//  require('./endIcon.png'),
							var startIcon = new BMap.Icon(
								this.startIcon,
								new BMap.Size(50, 50)
							);
							var endIcon = new BMap.Icon(
								this.endIcon,
								new BMap.Size(50, 50)
							);
							let marker = new BMap.Marker(startPoint, {
								icon: startIcon,
							});
							let endmarker = new BMap.Marker(endPoint, {
								icon: endIcon,
							});
							this.map.addOverlay(marker);
							this.map.addOverlay(endmarker);

							// 是否可进行回放
							this.$emit("haslist", true);
						} else {
							this.$emit("haslist", false);
						}

						// 路书
						this.getLushu();

						// 表格数据
						this.getTableList();
						this.total = res.detail.length;

						this.loading = false;

						//test 可删除
						// if (this.global.isDev) {
						//     let stationList = this.$util.getStor('stationList');
						//     this.setMarker(stationList);
						// }
					},
					() => {
						this.loading = false;
					}
				);
			},
			// 创建轨迹
			getPolyline(trackList) {
				let tempList = [trackList[0]];
				for (let index = 1; index < trackList.length; index++) {
					const f = trackList[index - 1];
					const l = trackList[index];
					if (BMapLib.GeoUtils.getDistance(f, l) <= 300) {
						tempList.push(l);
					} else {
						// 绘制
						const polyline = new BMap.Polyline(tempList, {
							strokeColor: "blue", //设置颜色
							strokeWeight: 5, //宽度
							strokeOpacity: 0.5, //透明度
						});
						this.map.addOverlay(polyline);
						tempList = [l];
					}
				}
				if (tempList.length > 1) {
					const polyline = new BMap.Polyline(tempList, {
						strokeColor: "blue", //设置颜色
						strokeWeight: 5, //宽度
						strokeOpacity: 0.5, //透明度
					});
					this.map.addOverlay(polyline);
				}
			},
			// 创建路书
			getLushu() {
				this.lushu = new BMapLib.LuShu(this.map, this.gpsData, {
					defaultContent: "", //"从天安门到百度大厦"
					autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
					icon: new BMap.Icon(
						"https://developer.baidu.com/map/jsdemo/img/car.png",
						new BMap.Size(52, 26),
						{ anchor: new BMap.Size(27, 13) }
					),
					speed: this.speed * this.option.multiple, //运行速度
					enableRotation: true, //是否设置marker随着道路的走向进行旋转
					landmarkPois: [
						{
							lng: 116.314782,
							lat: 39.913508,
							html: "加油站",
							pauseTime: 2,
						},
					],
				});
			},
			// 开始
			initStart(option) {
				this.lushu._opts.speed = this.speed * option.multiple; // 修改速度
				this.lushu.start();
			},
			// 停止
			initStop() {
				this.lushu.stop();
			},
			// 暂停
			initPause() {
				this.lushu.pause();
			},
			setIsHide(b) {
				if (b !== undefined) {
					this.isHide = b;
				} else {
					this.isHide = !this.isHide;
				}
				this.$emit("isHide", this.isHide);
			},
			// 表格数据分页，转换经纬度
			getTableList() {
				// 分页
				let start = (this.pageIndex - 1) * this.pageSize;
				let end = this.pageIndex * this.pageSize;
				let list = this.gpsData.slice(start, end);
				this.dataList = list;

				var gc = new BMap.Geocoder();
				for (let i = 0; i < list.length; i++) {
					var p0 = list[i].lng;
					var p1 = list[i].lat;
					var point = new BMap.Point(p0, p1);

					//   获取详细位置
					var _that = this;
					gc.getLocation(point, function (rs) {
						_that.dataList[i].address = rs.address;
						_that.$set(_that.dataList, i, _that.dataList[i]);
					});
				}
			},
			// 分页
			handleSizeChange(val) {
				// 每页条数
				this.pageSize = val;
				this.getTableList();
			},
			handleCurrentChange(val) {
				// 当前页条数
				this.pageIndex = val;
				this.getTableList();
			},
			setMarker(list) {
				list = list ? list : this.stationList;
				list.forEach((item) => {
					let point = new window.BMap.Point(item.lng, item.lat);
					let marker = new BMap.Marker(point);
					this.map.addOverlay(marker);
					let label = new BMap.Label(
						item.name + "(" + item.lat + ")" + "(" + item.lng + ")",
						{ offset: new BMap.Size(5, 3) }
					);
					label.setStyle({
						background: "none",
						color: "#0000aa",
						border: "none",
					});
					marker.setLabel(label);
				});
			},
		},
	};
</script>
<style lang='scss' scoped>
	$anima_time: 0.5s;

	.track_map {
		height: 100%;
		width: 100%;
		position: relative;

		#track_map_box {
			height: 80vh;
		}

		.trackReplay_table {
			width: 100%;
			height: 315px;
			transition: height $anima_time;
			position: absolute;
			bottom: -30px;
			background: #ffffff;
			z-index: 90000;
			overflow: hidden;

			.arrow_box {
				width: 100%;
				height: 25px;
				line-height: 25px;
				border-bottom: 1px solid $bordercolor;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #edf0f5;
				@extend .hand;

				.i_box {
					transition: transform $anima_time;

					&.to_left {
						transform: rotate(180deg);
					}
				}
			}

			&.hidden {
				height: 25px;
			}

			.jy_table {
				overflow: hidden;
			}
		}
	}
</style>
